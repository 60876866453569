















































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsListNameModal',
})
export default class extends Vue {
    private name = ''
    private dialog = false
    private loading = false
    resolve: any = null
    reject: any = null
    $refs: any

    public async save () {
        this.loading = true
        const valid = await this.$refs.nameForm.validate()
        if (!valid) return
        this.resolve(this.name)
    }

    public open (name: string): Promise<any> {
        this.loading = false
        this.dialog = true
        this.name = name
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    public cancel (): void {
        this.reject(false)
    }

    public close () {
        this.dialog = false
    }
}
